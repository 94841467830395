import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

const DesktopMenu = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: inherit;
`;

function Navigation() {
  return (
    <DesktopMenu>
      <h2>
        <NavLink exact activeClassName="underlined" to="/">
          HOME
        </NavLink>
      </h2>
      <h2>
        <NavLink activeClassName="underlined" to="/blog">
          BLOG
        </NavLink>
      </h2>
      <h2>
        <NavLink activeClassName="underlined" to="/about">
          ABOUT
        </NavLink>
      </h2>
      <h2>
        <NavLink activeClassName="underlined" to="/contact">
          CONTACT
        </NavLink>
      </h2>
    </DesktopMenu>
  );
}

export default Navigation;
