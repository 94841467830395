import React from "react";

import Content from "../components/Content.js";
import Frame from "../components/Frame.js";
import Post from "../components/Post.js";

function Blog({ habits }) {
  return (
    <Content habits={habits}>
      <Frame habits={habits} />
      <Post
        postdate={"TUE 25th AUGUST 2020"}
        posttitle={"UPDATES ARE DONE!"}
        postcontent={
          "Last week after putting this landing page online I made a post on Indie Hackers (a very nice platform where developers share their projects) and I received some amazing comment and incredibly useful feedback! \n \nSo I put myself to work and the first changes are finally done, I hope you like them :)"
        }
      />
      <Frame habits={habits} />
      <Post
        postdate={"MON 17th AUGUST 2020"}
        posttitle={"WELCOME!"}
        postcontent={
          "Hello everyone! My name is Nora, I'm the one building habitual :) \n \nI love habit tracking spreads - I use them a lot in my bullet journal. Although I love having a physical journal and writing things down “pen and paper”, I always found many aspects of it very inconvenient. It’s annoying to have each month's tracker scattered everywhere in the journal, having to go back and forth to attempt any comparisons between months. It’s annoying that if I don’t have my journal with me I forget to track anything I do. It’s annoying to correct mistakes, it’s annoying to write down tens and tens of calendars. \nSo I decided to make an online version for myself. \n \nI wanted it to be simple, easy, nice to use and pleasant to look at. \n \nI used to be a maritime conservator (think shipwrecks more than corals and fishes) until I recently changed carreer and taught myself to program. Habitual is my first app. I planned it, made the art work, developed it, made mistakes, changed my mind a thousands times. And I have so many more ideas to improve it. It’s not perfect but I’m so proud of it! \n \n So thank you for taking an interest in habitual, I hope it won’t disappoint you!"
        }
      />
    </Content>
  );
}

export default Blog;
