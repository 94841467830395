import React from "react";
import styled from "@emotion/styled";

import Header from "./Header.js";
import Frame from "./Frame.js";
import Footer from "./Footer.js";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  min-width: 510px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  margin: 0 6rem;
  border-radius: 1rem;
  padding: 2rem 6rem;
  @media (max-width: 420px) {
    min-width: 110px;
    margin: 0 2rem;
    padding: 1rem;
  }
`;

const Break = styled.div`
  min-height: 80px;
  flex-grow: 4;
`;

function Content({ habits, children }) {
  return (
    <FlexContainer habits={habits}>
      <Header />
      <Main habits={habits}>{children}</Main>
      <Break />
      <Frame habits={habits} />
      <Break />
      <Footer />
    </FlexContainer>
  );
}

export default Content;
