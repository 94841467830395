import React from "react";
import styled from "@emotion/styled";

import Navigation from "./Navigation.js";
import LogoLight from "./LogoLight.js";

const FooterContainer = styled.div`
  width: 100%;
  background-color: #b18597;
  background-color: #5b5b84;
  color: #fff3f3;
  text-align: center;
`;

const Top = styled.div`
  margin: 3rem;
`;

const Bottom = styled.div`
  font-size: 10px;
  border-top: 2px dashed #fff3f3;
  margin: 2rem;
  margin-bottom: 0.5rem;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 420px) {
    display: block;
  }
`;

function Footer() {
  return (
    <FooterContainer className="footer">
      <Top>
        <LogoLight />
        <h6>Talk with me :)</h6>
        <h6>hello@gethabitual.com</h6>
      </Top>
      <Bottom>
        <Navigation />
        <p> © habitual 2020. All rights reserved.</p>
      </Bottom>
    </FooterContainer>
  );
}

export default Footer;
