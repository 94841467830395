import React from "react";
import styled from "@emotion/styled";

const JoinButton = styled.button`
  font-family: "Amatic SC", cursive;
  font-size: 14px;
  @media (max-width: 420px) {
    font-size: 13px;
  }
  text-transform: upperCase;
  outline: none;
  position: relative;
  cursor: pointer;
  width: 15em;
  height: 4em;
  margin: 1em;
  margin-bottom: 3em;
  background: #fef9f8;
  border: 2px solid #b18597;
  border-radius: 5em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    background 150ms cubic-bezier(0, 0, 0.58, 1);

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f9c4d2;
    border-radius: 5em;
    box-shadow: 0 0 2px #b18597;
    box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 0 #ffe3e2;
    transform: translate3d(0, 0.75em, -1em);
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
  }

  &:hover {
    transform: translate(0, 0.25em);
    &::before {
      box-shadow: 0 0 0 2px #b18597, 0 0.5em 0 0 #ffe3e2;
      transform: translate3d(0, 0.5em, -1em);
    }
  }

  &:active {
    animation: clickUp 400ms cubic-bezier(0.33, 1, 0.68, 1);
    &::before {
      animation: clickUpBefore 400ms cubic-bezier(0.33, 1, 0.68, 1);
    }
  }

  @keyframes clickUp {
    0% {
      transform: translate(0, 0.25em);
    }
    70% {
      transform: translate(0, 0.75em);
    }
    100% {
      transform: translate(0);
    }
  }

  @keyframes clickUpBefore {
    0% {
      box-shadow: 0 0 0 5px #b18597, 0 0.5em 0 0 #ffe3e2;
      transform: translate3d(0, 0.5em, -1em);
    }
    70% {
      box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
      transform: translate3d(0, 0, -1em);
    }
    100% {
      box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 1px #ffe3e2;
      transform: translate3d(0, 0.75em, -1em);
    }
  }
  @media (max-width: 420px) {
    width: 9em;
  }
`;

function Button() {
  return (
    <JoinButton
      onClick={() =>
        window.open("https://landing.mailerlite.com/webforms/landing/m4g5r0")
      }
    >
      <h3>Get habitual</h3>
    </JoinButton>
  );
}

export default Button;
