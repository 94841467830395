import React from "react";
import styled from "@emotion/styled";
import Mockup from "./Mockup.js";

const InstructionStep = styled.div`
  display: grid;
  grid-template-rows: 50px auto;
  grid-template-columns: auto auto;
  margin: 5% 10%;
  @media (max-width: 420px) {
    margin: 10% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StepTitle = styled.h3`
  font-size: 2.5rem;
  margin: 0 1rem;
  grid-column: ${(props) => (props.isMiddle ? "1 / 2" : "2 / 3")};
  grid-row: 1 / 2;
`;

const Phone = styled.div`
  grid-column: ${(props) => (props.isMiddle ? "2 / 3" : "1 / 2")};
  grid-row: 1 / span 2;
  align-self: start;
  width: 170px;
  height: 100%;
  @media (max-width: 420px) {
    margin-top: 1rem;
    align-self: center;
  }
`;

const Description = styled.p`
  grid-column: ${(props) => (props.isMiddle ? "1 / 2" : "2 / 3")};
  grid-row: 2 / 3;
`;

function Step(props) {
  console.log(props);
  return (
    <InstructionStep>
      <StepTitle isMiddle={props.isMiddle}>{props.title}</StepTitle>
      <Phone isMiddle={props.isMiddle}>
        <Mockup video={props.video} />
      </Phone>
      <Description isMiddle={props.isMiddle}>{props.paragraph}</Description>
    </InstructionStep>
  );
}

export default Step;
