import React from "react";
import styled from "@emotion/styled";

import Content from "../components/Content.js";
import Dashed from "../components/Dashed.js";
import Frame from "../components/Frame.js";
import Button from "../components/Button.js";
import Step from "../components/Step.js";

const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const HeroShot = styled.h1`
  font-size: 3.6rem;
  letter-spacing: 4px;
  @media (max-width: 420px) {
    font-size: 3rem;
    letter-spacing: 2px;
    padding: 0rem;
  }
`;

function Home({ habits }) {
  return (
    <Content habits={habits}>
      <Frame habits={habits} />
      <HeroShot>
        Build habits simply.
        <br /> Track easily. <br />
        Nice :)
      </HeroShot>
      <p>
        Habitual is the habit tracker you won't stop using
        <br />
        because it's simple, easy, and nice to use.
      </p>
      <p>
        Plus it's cute as a BUTTON
        <br /> (see what I did there??).
      </p>
      <Button></Button>
      <p>
        -- Habitual is still in development, but if you are interested click the
        button above and I will let you know as soon as it's ready! --
      </p>
      <Dashed>
        <h1>easy peasy</h1>
        <Instructions>
          <Step
            title={"1: add"}
            video={"videos/step_1.mp4"}
            paragraph={
              "Select the habits you want to build into your routine. You can add as many as you want!"
            }
            isMiddle={false}
          />
          <Step
            title={"2: track"}
            video={"videos/step_2.mp4"}
            paragraph={
              "Any time you complete a (soon to be) habit, click its button. Habitual lives in your phone so you always have your tracker with you. You can even set up notifications so you won't forget!"
            }
            isMiddle={true}
          />
          <Step
            title={"3: chart"}
            video={"videos/step_3.mp4"}
            paragraph={
              "Habitual records your progress with automatically generated visualizations so you can keep track of your improvements and share your success!"
            }
            isMiddle={false}
          />
        </Instructions>

        <Button></Button>
      </Dashed>
    </Content>
  );
}

export default Home;
