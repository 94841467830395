import React from "react";
import styled from "@emotion/styled";



const DashedSection = styled.div`
  margin: 4rem;
  width: 75%;
  max-width: 800px;
  min-width: 500px;
  border: 2px dashed black;
  border-radius: 1.6rem;
  padding: 2rem;
  @media (max-width: 420px) {
    width: 90%;
    min-width: 160px;
    margin: 1rem;
    padding: 1rem;
  }
`;

function Dashed({ children }) {
  return <DashedSection>{children}</DashedSection>;
}

export default Dashed;
