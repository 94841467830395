import React from "react";

import Frame from "../components/Frame.js";
import Button from "../components/Button.js";
import Content from "../components/Content.js";
import Dashed from "../components/Dashed.js";

function About({ habits, setHabits }) {
  return (
    <Content habits={habits}>
      <p>
        Habitual helps you establish new habits, stay on track with your
        routines and meet your goals!
      </p>
      <p>
        Habitual was crerated to help you keep track of your effort in building
        routines and to keep your motivation high! <br />
        It was inspired by habit tracker spreads often used in bullet
        journaling, but with less writing and doodoling involved.
      </p>

      <Frame habits={habits} />
      <Dashed>
        <p>Hi! I’m Nora. </p>
        <p>
          I made Habitual as a passion project. I love habit tracker spreads --
          I use them a lot in my bullet journal. Although I love having a
          physical journal and writing things down “pen and paper”, I always
          found many aspects of it very inconvenient. It’s annoying to have each
          month's tracker scattered everywhere in the journal, having to go back
          and forth to attempt any comparisons between months. It’s annoying
          that if I don’t have my journal with me I forget to track anything I
          do. It’s annoying to correct mistakes, it’s annoying to write down
          tens and tens of calendars. So I decided to make an online version for
          myself.
        </p>
        <h6>
          I wanted it to be simple, easy, nice to use and pleasant to look at.
        </h6>
        <p>
          I used to be a maritime conservator (think shipwrecks more than corals
          and fishes) until I recently changed carreer and taught myself to
          program. Habitual is my first app. I planned it, made the art work,
          developed it, made mistakes, changed my mind a thousands times. And I
          have so many more ideas to improve it. It’s not perfect but I’m so
          proud of it!
        </p>

        <p>
          So thank you for taking an interest in Habitual, I hope it won’t
          disappoint you!
        </p>
      </Dashed>

      <Frame habits={habits} />

      <h5>What is a habit tracker?</h5>

      <p>
        Put in the words of James Clear - author of "Atomic Habits" - a habit
        tracker is a simple way to measure whether you did a habit. The most
        basic and old school form of habit tracker is having a calendar and
        crossing off each day you stick with your routine.
      </p>
      <p>
        Why would you want to use such a thing you ask? Because habit forming
        takes time, and a habit traker can help you visualise the progress you
        are making. It can give you that immediate feedback and reward even when
        the desired achievements are yet to show.
      </p>

      <h5>Whom is Habitual made for?</h5>

      <p>
        Anyone! If you have a goal (or many) that will take more than one day to
        achieve then Habitual is made for you. If you like working on yourself,
        if you are focused on personal development, Habitual can encourage you
        mantain your efforts. Or if you know you have some bad habits you want
        to get rid of, Habitual can halp you assess them.
      </p>

      <h5>How does Habitual work?</h5>

      <p>
        Habitual helps you keep track of your habits, however often you want to
        perform them. You first select the habits you want to track: these will
        appear as buttons on your main page. When you do a habit you click the
        button of the habit, which will appeared as “clicked” for the day. You
        can always unclick the habit if you want. Every day at midnight the the
        buttons reset. The data is visualised as little calendars, one for each
        habit, with the days marked when you stuck to your routine. Easy peasy!
      </p>

      <h5>When is Habitual launching</h5>

      <p>
        It’s almost ready! It just need some polishing to make sure it works
        perfectly. If you want to have it as soon as it’s available, click the
        get Habitual button and we will send you the instructions when the time
        comes (soooooon!)
      </p>

      <h5>Where can I use Habitual</h5>

      <p>
        Habitual is an app that will be available both on Apple Store and Google
        Play.
      </p>
      <Button></Button>
    </Content>
  );
}

export default About;
