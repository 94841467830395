import React from "react";
import styled from "@emotion/styled";

import LogoImgLight from "../images/Logo/logo-light.svg";

const LogoIcon = styled.img`
  width: 5rem;
`;

function LogoLight(props) {
  return <LogoIcon src={LogoImgLight} />;
}

export default LogoLight;
