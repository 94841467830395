import React from "react";
import styled from "@emotion/styled";

import LogoImg from "../images/Logo/logo-1a.svg";

const LogoIcon = styled.img`
  width: 8rem;
  min-width: 6rem;
  @media (max-width: 420px) {
    width: 5rem;
  }
`;

function Logo() {
  return <LogoIcon src={LogoImg} />;
}

export default Logo;
