import React from "react";

import Content from "../components/Content.js";

function Contact({ habits }) {
  return (
    <Content habits={habits}>
      <p>I would love to hear from you!</p>
      <p>
        If you would like to get in touch with me for questions or suggestions
        you can write to
        <h6>hello@gethabitual.com</h6>
      </p>
    </Content>
  );
}

export default Contact;
