import React from "react";
import styled from "@emotion/styled";

const FrameIcon = styled.img`
  width: 10%;
  max-width: 85px;
  height: 10%;
  padding: 5px;
  @media (max-width: 420px) {
    padding: 2px;
  }
`;

const FrameContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 1% 20%;
  padding: 1rem 0;
  min-width: 400px;
  @media (max-width: 420px) {
    margin: 1% 10%;
    min-width: 50px;
  }
`;

function Frame({ habits }) {
  const IconList = Object.keys(habits).map((habitName) => {
    const habit = habits[habitName];
    return <FrameIcon src={habit.icon} />;
  });

  return <FrameContainer>{IconList}</FrameContainer>;
}

export default Frame;
