import React from "react";
import styled from "@emotion/styled";

import Dashed from "../components/Dashed.js";

const Date = styled.h4`
  text-align: right;
`;

const Title = styled.h2`
  font-weight: bold;
  text-align: left;
`;

const PostContent = styled.div`
  text-align: left;
  white-space: pre-wrap;
  font-family: "Gaegu", cursive;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: -1px;
  padding: 1rem;
`;

function Post(props) {
  return (
    <Dashed>
      <Date>{props.postdate}</Date>
      <Title>{props.posttitle}</Title>
      <PostContent>{props.postcontent}</PostContent>
    </Dashed>
  );
}

export default Post;
