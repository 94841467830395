import React from "react";
import styled from "@emotion/styled";
import ReactPlayer from "react-player";
import Phone from "../images/mockup.svg";

const MockupContainer = styled.div`
  position: relative;
  height: 370px;
  width: 170px;
`;

const PhoneFrame = styled.img`
  height: 100%;
`;

function Mockup(props) {
  return (
    <MockupContainer>
      <ReactPlayer
        url={props.video}
        width="100%"
        height="100%"
        autoPlay
        muted
        playing={true}
        loop={true}
        playsinline
        style={{
          position: "absolute",
          padding: "8px",
        }}
      />
      <PhoneFrame src={Phone} />
    </MockupContainer>
  );
}

export default Mockup;
