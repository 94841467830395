import React from "react";
import styled from "@emotion/styled";
import { bubble as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";

import Navigation from "./Navigation.js";
import Logo from "./Logo.js";
import LogoLight from "./LogoLight.js";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 2.5rem;
`;

const Desktop = styled.div`
  @media (max-width: 420px) {
    display: none;
  }
`;

const MobileBurger = styled.div`
  display: none;
  @media (max-width: 420px) {
    display: block;
  }
`;

function Header() {
  return (
    <HeaderContainer className="header">
      <NavLink exact to="/">
        <Logo />
      </NavLink>
      <Desktop>
        <Navigation />
      </Desktop>
      <MobileBurger>
        <Menu>
          <LogoLight />
          <h2>
            <NavLink exact activeClassName="underlined" to="/">
              HOME
            </NavLink>
          </h2>
          <h2>
            <NavLink activeClassName="underlined" to="/blog">
              BLOG
            </NavLink>
          </h2>
          <h2>
            <NavLink activeClassName="underlined" to="/about">
              ABOUT
            </NavLink>
          </h2>
          <h2>
            <NavLink activeClassName="underlined" to="/contact">
              CONTACT
            </NavLink>
          </h2>
        </Menu>
      </MobileBurger>
    </HeaderContainer>
  );
}

export default Header;
