import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Global, css } from "@emotion/core";

import iCardio from "./images/icons-svg/icon-cardio.svg";
import iEarlyRise from "./images/icons-svg/icon-earlyrise.svg";
import iHealthy from "./images/icons-svg/icon-healthy.svg";
import iHydration from "./images/icons-svg/icon-hydration.svg";
import iInstrument from "./images/icons-svg/icon-instrument.svg";
import iLanguage from "./images/icons-svg/icon-language.svg";
import iMeditation from "./images/icons-svg/icon-meditation.svg";
import iRead from "./images/icons-svg/icon-read.svg";
import iVitamins from "./images/icons-svg/icon-vitamins.svg";
import iWorkout from "./images/icons-svg/icon-workout.svg";
import iYoga from "./images/icons-svg/icon-yoga.svg";

import Home from "./pages/Home.js";
import Contact from "./pages/Contact.js";
import About from "./pages/About.js";
import Blog from "./pages/Blog.js";
import ScrollToTop from "./components/ScrollToTop.js";

function App() {
  const [habits, setHabits] = useState({
    cardio: {
      name: "cardio",
      icon: iCardio,
    },
    earlyRise: {
      name: "early rise",
      icon: iEarlyRise,
    },
    healthy: {
      name: "eat healthly",
      icon: iHealthy,
    },
    hydration: {
      name: "hydrate",
      icon: iHydration,
    },
    instrument: {
      name: "play an instrument",
      icon: iInstrument,
    },
    language: {
      name: "learn a language",
      icon: iLanguage,
    },
    meditation: {
      name: "meditate",
      icon: iMeditation,
    },
    vitamins: {
      name: "take vitamins",
      icon: iVitamins,
    },
    yoga: {
      name: "yoga",
      icon: iYoga,
    },
    workout: {
      name: "workout",
      icon: iWorkout,
    },
    read: {
      name: "read",
      icon: iRead,
    },
  });

  return (
    <Router>
      <ScrollToTop />
      <Global
        styles={css`
          html {
            height: 100%;
          }

          * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 14px;

            @media (max-width: 420px) {
              font-size: 13px;
            }
          }

          :focus {
            outline: 0;
          }

          body,
          #root {
            background-color: #fff3f3;
            color: black;
            font-family: "Amatic SC", cursive;
            text-align: center;
            height: 100%;
            width: 100%;
            min-width: 650px;
            @media (max-width: 420px) {
              min-width: 200px;
            }
          }

          .footer {
            align-self: end;
          }

          h1 {
            font-size: 3.2rem;
            text-transform: upperCase;
            font-weight: bold;
            margin: 1rem;
            letter-spacing: 2px;
            @media (max-width: 420px) {
              font-size: 2.8rem;
            }
          }

          h2 {
            font-size: 2.5em;
            text-transform: upperCase;
            font-weight: normal;
            margin: 0 1rem;
          }

          h3 {
            font-size: 2rem;
            text-transform: upperCase;
          }

          h4 {
            font-family: "Gaegu", cursive;
            text-transform: upperCase;
            font-size: 1.4rem;
          }

          h5 {
            font-family: "Gaegu", cursive;
            font-size: 1.6rem;
            font-weight: bold;
            padding-top: 2rem;
          }

          h6 {
            font-family: "Gaegu", cursive;
            font-size: 1.4rem;
            font-weight: bold;
          }

          p {
            font-family: "Gaegu", cursive;
            font-size: 1.4rem;
            line-height: 1.5;
            letter-spacing: -1px;
            padding: 1rem;
            text-align: center;
          }

          a {
            font-size: inherit;
            font-family: inherit;
            color: inherit;
            text-decoration: none;
          }

          .underlined {
            text-decoration: underline;
          }

          .bm-burger-button {
            position: relative;
            width: 2rem;
            height: 1.5rem;
          }

          /* Color/shape of burger icon bars */
          .bm-burger-bars {
            background: black;
          }

          /* Color/shape of burger icon bars on hover*/
          .bm-burger-bars-hover {
            background: #b18597;
          }

          /* Color/shape of close button cross */
          .bm-cross {
            background: #fff3f3 !important;
          }

          /* 
          Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
          */

          /* Sidebar wrapper styles */
          .bm-menu-wrap {
            position: fixed;
            width: 100% !important;
            height: 100%;
            left: 0;
            top: 0;
            text-align: center;
          }

          /* General sidebar styles */
          .bm-menu {
            width: 80%;
            background: #5b5b84;
            margin: 20% 10%;
          }

          /* Morph shape necessary with bubble or elastic */
          .bm-morph-shape {
            fill: #5b5b84;
          }

          /* Wrapper for item list */
          .bm-item-list {
            color: #fff3f3;
          }

          /* Individual item */
          .bm-item {
            display: inline-block;
            margin: 10px;
          }

          /* Styling of overlay */
          .bm-overlay {
            background: transparent !important;
            top: 0 !important;
          }
        `}
      />
      <Switch>
        <Route
          render={() => <Home habits={habits} setHabits={setHabits} />}
          exact
          path="/"
        />

        <Route
          render={() => <Blog habits={habits} setHabits={setHabits} />}
          path="/blog"
        />

        <Route
          render={() => <Contact habits={habits} setHabits={setHabits} />}
          path="/contact"
        />

        <Route
          render={() => <About habits={habits} setHabits={setHabits} />}
          path="/about"
        />
      </Switch>
    </Router>
  );
}

export default App;
